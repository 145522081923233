// コメント位置はPrettier対策
class AppCertification {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APPVERSION = "24.11.02";
  APP_CONFIG = {
    //FIXME MockModeの有効無効
    // HttpClientのgetを対象として、dummy.jsonを読み込むフラグです。
    MOCK_MODE: false,

    /**
     * API接続用設定（VDI外アクセス）
     */
    // ステージング環境
    BASE_URL: "https://stg-next-wms.nikkon.jp/",
  };

  /**
   * Cognitoの設定です。
   */
  //FIXME 本番設定に書き換えてください。
  //営業所用
  COGNITO = {
    USER_POOL_ID: "ap-northeast-1_zQ5xffDY1",
    APP_CLIENT_ID: "743qn0o3rc97dnuja91964ls1f",
  };

  //荷主用
  SHIPPER_COGNITO = {
    USER_POOL_ID: "ap-northeast-1_jHhbolQzz",
    APP_CLIENT_ID: "743qn0o3rc97dnuja91964ls1f",
  };

  /** datadog */
  DATADOG = {
    DD_APPLICATION_ID: "b9829cd3-a878-44a7-9462-72a2e1bd10d3",
    DD_CLIENT_TOKEN: "pub53c089a96e56308452bac5d5dc221d35",
    DD_SERVICE: "wms-st-pc",
    DD_ENV: "staging",
    DD_SESSION: 20,
  };
  /** datadog */
}

export const appCertification = new AppCertification();
